@import url("https://fonts.googleapis.com/css2?family=Protest+Revolution&display=swap");

@font-face {
  font-family: "manrope";
  src: url("./fonts/manrope.woff2") format("woff2");
}

.App {
  text-align: center;
  color: white;
  min-height: 100vh; /* Ensures full viewport height */
  background-size: cover; /* Adjust as necessary */
}

.header {
  box-sizing: border-box;
  height: auto; /* Adjusted for dynamic content height */
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100; /* Ensure it's above other elements */
}

#logo img {
  max-height: 80px; /* Adjust based on design preference */
  width: auto;
}

#menu,
#social {
  display: flex;
  list-style: none; /* Removes default list styling */
  gap: 20px; /* Space between social icons */
  color: #fff; /* Or any color that matches your design */
}

#menu li,
#social li {
  display: inline-block;
}

#menu a,
#social a {
  display: flex;
  align-items: center;
}

#social img {
  max-height: 30px; /* Smaller icons for all views */
  width: auto;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* This will vertically center the content */
  min-height: 60vh;
}

.hero-image-container {
  width: 80%;
  max-width: 600px;
  margin-bottom: 50px;
}

.hero-image-container img {
  width: 100%;
  height: auto;
}

.hero-heading-container {
  font-family: "Protest Revolution", sans-serif;
}

.Section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.Section1-heading-container {
  margin-bottom: 3rem;
}

.Section1-image-container {
  width: 50%;
}

.Section1-image-container img {
  width: 100%;
  height: auto;
}

.Section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.Section2-heading-container {
  margin-bottom: 3rem;
}

.Section2-image-container {
  width: 95%;
}

.Section2-image-container img {
  width: 100%;
  height: auto;
}

h1 {
  font-family: "Protest Revolution", sans-serif;
  font-size: 3rem;
  font-weight: 100;
  padding: 0.15rem;
}

.buy-button {
  background-color: #f8f9fa; /* replace with your preferred color */
  font-family: "Protest Revolution", sans-serif;
  font-weight: 800;
  color: green;
  padding: 10px 10px;
  text-decoration: none;
  margin-left: 10px;
  border-radius: 10px;
}

/* Adjustments for larger screens */
@media (min-width: 600px) {
  .buy-button {
    padding: 10px 20px;
    text-decoration: none;
    font-size: 1.5rem;
  }

  .Section1-heading-container {
    margin-top: 3rem;
  }

  .Section1-image-container {
    width: 25%;
  }

  .Section2-image-container {
    width: 75%;
  }

  #logo img {
    max-height: 100px; /* Larger logo for desktop */
  }

  #social img {
    max-height: 50px; /* Slightly larger icons for desktop */
  }

  .header {
    padding: 1rem 10rem; /* More padding on larger screens */
  }
}

.info-strip {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center-align items horizontally */
  justify-content: center; /* Center content vertically for a column layout */
  margin: 20px 0; /* Add some margin above and below */
  color: #fff; /* Adjust text color as needed */
  background-color: rgba(
    192,
    192,
    192,
    0.5
  ); /* Transparent silver background */
  padding: 10px;
  border-radius: 5px;
  position: sticky;
  bottom: 0;
}

.contract-info,
.buy-links {
  margin: 5px 0;
}

.buy-links a {
  margin: 0 5px;
}

@media (min-width: 768px) {
  .info-strip {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .contract-info,
  .buy-links {
    display: flex;
    align-items: center;
    gap: 1px;
  }

  .buy-links {
    gap: 10px;
    font-size: 12px;
  }

  .link {
    background-color: #333;
    padding: 2px 4px;
    border-radius: 4px;
  }

  .link:hover::after,
  link:focus::after {
    opacity: 1;
  }
}

.contract-wrapper {
  font-family: monospace;
  display: inline-flex;
  align-items: center;
  background-color: #333; /* Light grey background */
  border-radius: 4px; /* Rounded corners */
  padding: 0.2rem 0.5rem; /* Some padding around the text */
  font-family: monospace; /* Monospaced font for code-like appearance */
  margin-right: 1px; /* Space before the separator */
}

.contract {
  margin: 0; /* Remove default margin from p tag */
  color: #fff; /* Dark text color for contrast */
}

.copy-icon {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff; /* Dark text color for contrast */
  margin-left: 1px;
  max-height: 15px; /* Space between text and icon */
  max-width: 15px; /* Space between text and icon */
}

.main-separator,
.separator {
  color: #333; /* Dark text color for contrast */
  padding: 0 10px; /* Space around the separator */
}

@media (max-width: 600px) {
  .main-separator {
    display: none;
  }

  .copy-icon {
    margin-top: 10px;
  }

  .link {
    background-color: #333;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
  }

  .link:hover::after,
  link:focus::after {
    opacity: 1;
  }
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  animation: fadein 0.5s, fadeout 0.5s 1.5s;
  font-size: 12px;
  letter-spacing: 1px;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 20px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 20px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
